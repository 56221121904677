<template>
  <div>
    <p
      @click="clickBadge()"
      class="change-company mb-0 mr-2"
    >
      {{ nameCompany }}
    </p>
    <modal-companies
        v-if="canChangeCompanies"
    />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import modalCompanies from '@/components/companies/ModalCompanies.vue'

export default {
  components: {
    BBadge,
    modalCompanies,
  },
  data() {
    return {
      canChangeCompanies: false,
      chargeList: false,
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      listCompanies: 'companies/getListCompanies',
      role: 'auth/getRole',
    }),
    nameCompany() {
      if (this.currentCompany) {
        return this.currentCompany.name
      }
      return ''
    },
  },
  async created() {
    if (!this.currentCompany) {
      this.chargeList = true
      await this.searchCompanies()
        .then(() => {
          this.checkList()
        })
    } else {
      this.checkList()
    }
  },
  methods: {
    ...mapActions({
      searchCompanies: 'companies/searchCompanies',
    }),
    ...mapMutations({
      setShowModalCompanies: 'companies/setShowModalCompanies',
      setCurrentCompany: 'companies/setCurrentCompany',
    }),
    checkList() {
      if (this.listCompanies.length === 1) {
        if (this.currentCompany === '' || this.currentCompany === null) {
          this.setCurrentCompany(this.listCompanies[0])
        }
      } else if (this.listCompanies.length > 1) {
        this.canChangeCompanies = true
      }
    },
    async clickBadge() {
      if (!this.chargeList) {
        await this.searchCompanies()
          .then(() => {
            this.checkList()
            this.chargeList = true
            if (this.canChangeCompanies) {
              this.setShowModalCompanies(true)
            }
          })
      } else if (this.canChangeCompanies) {
        this.setShowModalCompanies(true)
      }
    },
  },
}
</script>
