const types = JSON.parse(localStorage.getItem('typesAvailables'))
const output = [
  {
    title: 'Inicio',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
]

if (types) {
  if (types.installations) {
    output.push({
      title: 'installations',
      route: 'installations',
      icon: 'CpuIcon',
    })
  }
  if (types.assets) {
    output.push({
      title: 'assets',
      route: 'assets',
      icon: 'DatabaseIcon',
    })
  }
  if (types.epis) {
    output.push({
      title: 'epis',
      route: 'epis',
      icon: 'ServerIcon',
    })
  }
}

const empresaChildrens = [
  {
    title: 'Datos empresa',
    route: 'viewClient',
  },
  {
    title: 'Localizaciones',
    route: 'locations',
  },
]

output.push(
  {
    title: 'Tareas',
    route: 'tasks',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Mantenimiento',
    route: 'maintenance',
    icon: 'ListIcon',
  },
  {
    title: 'Gestión',
    icon: 'SettingsIcon',
    children: empresaChildrens,
  },
)

export default output
