export default [
  {
    title: 'Clientes',
    route: 'clients',
    icon: 'UserIcon',
  },
  {
    title: 'Gestión',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Eventos',
        route: 'events',
      },
      {
        title: 'Formularios',
        route: 'forms',
      },
      {
        title: 'Cliente nuevo',
        route: 'importClient',
      },
    ],
  },
]
