<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language mr-2"
    right
  >
    <template #button-content>
      <img
        :src="imageLang(currentLang)"
        style="width: 22px; height: auto;"
        class="img-fluid"
      >
      <span class="ml-50 text-body">{{ $t(currentLang) }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in languages"
      :key="localeObj.languages"
      @click="changeLang(localeObj)"
    >
      <span class="ml-50">{{ $t(localeObj) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters({
      languages: 'languages/getLanguages',
      currentLang: 'languages/getCurrentLanguage',
    }),
  },
  methods: {
    ...mapMutations({
      setCurrentLanguage: 'languages/setCurrentLanguage',
    }),
    changeLang(val) {
      this.setCurrentLanguage(val)
      location.reload()
    },
    imageLang(currentLang) {
      return require(`@/assets/images/flags/${currentLang}.png`)
    },
  },
}
</script>

<style>

</style>
