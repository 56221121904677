<template>
  <div>
    <b-modal
        id="modalCompanies"
        hide-footer
        :hide-header="isHideHeader"
        centered
        size="lg"
        v-model="modalShow"
    >
      <b-form
          @submit.prevent
      >
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
              id="input-client"
              v-model="stringSearchCompany"
              :placeholder="$t('selectCompany')"
              @keyup="validationForm"
          ></b-form-input>
          <b-input-group-append>
            <b-button
                variant="primary"
                @click="validationForm"
            >{{ $t('Buscar') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <div class="mt-2" >
        <b-table
            :fields="fields"
            :items="listCompanies"
        >
          <template #cell(Nombre)="data">
            <span
                class="cursor-pointer"
                @click="clickCompany(data.item)"
            >
              {{ data.item.name }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BButton, BFormInput, BInputGroup, BModal, BInputGroupAppend, VBModal, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
  },
  computed: {
    ...mapGetters({
      listCompanies: 'companies/getListCompanies',
      currentCompany: 'companies/getCurrentCompany',
      user: 'auth/getUser',
      role: 'auth/getRole',
    }),
    modalShow: {
      get() { return this.$store.getters['companies/getShowModalCompanies'] },
      set(val) { this.$store.commit('companies/setShowModalCompanies', val) },
    },
    isHideHeader() {
      if (this.currentCompany) {
        return false
      }
      return true
    },
  },
  created() {
    if (this.currentCompany === '' || this.currentCompany === null) {
      this.modalShow = true
    }
  },
  data() {
    return {
      stringSearchCompany: '',
      fields: [
        {
          key: 'Nombre',
          label: this.$t('Todos'),
        },
      ],
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    ...mapActions({
      searchCompanies: 'companies/searchCompanies',
    }),
    ...mapMutations({
      setCurrentCompany: 'companies/setCurrentCompany',
      setShowModalCompanies: 'companies/setShowModalCompanies',
    }),
    validationForm() {
      this.searchCompanies(this.stringSearchCompany)
    },
    clickCompany(item) {
      this.setCurrentCompany(item)
      this.setShowModalCompanies(false)
    },
  },
}
</script>
