<template>
  <div>
    <p class="mb-0 mr-2 text-primary">
      <b-button
        variant="outline-primary"
        class="font-weight-bolder cursor-pointer"
        @click="clickBadge()"
      >{{ nameClient }}</b-button>
      <span
        v-if="currentClient && canChangeClient"
        class="ml-1 cursor-pointer"
        @click="clickClean()"
      >
        <feather-icon
          size="18"
          icon="XCircleIcon"
        />
      </span>
    </p>
    <modal-clients
      v-if="canChangeClient"
    />
  </div>
</template>

<script>
import { BBadge, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import modalClients from '@/components/clients/ModalClients.vue'

export default {
  components: {
    BBadge,
    BButton,
    modalClients,
  },
  data() {
    return {
      canChangeClient: false,
      chargeList: false,
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      listClients: 'clients/getListClients',
      role: 'auth/getRole',
      currentUser: 'auth/getUser',
    }),
    nameClient() {
      if (this.currentClient) {
        return this.currentClient.name
      }
      return this.$t('selectClient')
    },
  },
  watch: {
    async currentCompany() {
      await this.searchClients({
        company: this.currentCompany.id, page: 1, per_page: 10, search: this.stringSearchClient,
      })
        .then(() => {
          this.checkList()
        })
    },
  },
  methods: {
    ...mapActions({
      searchClients: 'clients/searchClients',
      getListClients: 'clients/getListClients',

    }),
    ...mapMutations({
      setShowModalClients: 'clients/setShowModalClients',
      setCurrentClient: 'clients/setCurrentClient',
    }),
    checkList() {
      console.log("hola")
      if (this.currentUser.clients.length === 1) {
        if ((this.role === 'admin_cliente' || this.role === 'prl_cliente') && (this.currentClient === '' || this.currentClient === null)) {
          this.setCurrentClient(this.currentUser.clients[0])
          this.canChangeClient = false
        } else if (this.role === 'admin_cliente' || this.role === 'prl_cliente') {
          this.canChangeClient = false
        } else {
          this.canChangeClient = true
        }
      } else if (this.listClients.length > 1) {
        this.canChangeClient = true
      } else if ((this.currentClient === '' || this.currentClient === null) && (this.role !== 'admin_empresa' && this.role !== 'super_admin')) {
        this.setCurrentClient(this.listClients[0])
      } else if (this.role === 'admin_empresa') {
        this.canChangeClient = true
      }
    },
    async clickClean() {
      this.setCurrentClient('')
    },
    async clickBadge() {
      if (this.canChangeClient) {
        if (!this.chargeList) {
          await this.getListClients({
            company: this.currentCompany.id, page: 1, per_page: 10, search: this.stringSearchClient,
          })
          // await this.searchClients({ company: this.currentCompany.id, search: '' })
            .then(() => {
              this.checkList()
              this.chargeList = true
              if (this.canChangeClient) {
                this.setShowModalClients(true)
              }
            })
        } else if (this.canChangeClient) {
          this.setShowModalClients(true)
        }
      }
    },
  },
  async created() {
    this.chargeList = true
    await this.getListClients({
      company: this.currentCompany.id, page: 1, per_page: 10, search: this.stringSearchClient,
    })
    // await this.searchClients({ company: this.currentCompany.id, search: '' })
      .then(() => {
        this.checkList()
      })
  },
}
</script>
