export default [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Clientes",
    route: "clients",
    icon: "UserIcon",
  },
  {
    title: "Etiquetas",
    icon: "TagIcon",
    children: [
      {
        title: "Etiquetas",
        route: "tags",
      },
      {
        title: "Series",
        route: "series",
      },
    ],
  },
  {
    title: "GestionProductos",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Productos",
        route: "products",
      },
      {
        title: "Agrupaciones",
        route: "sets",
      },
      {
        title: "Categorías",
        route: "categories",
      }
    ],
  },
  {
    title: "Empresa",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "Localizaciones",
        route: "locations",
      },
      {
        title: "Usuarios",
        route: "users",
      },
      {
        title: "Notificaciones",
        route: "notifications",
      },
    ],
  },
];
