<template>
  <div>
    <b-modal
      id="modalClients"
      hide-footer
      :hide-header="isHideHeader"
      centered
      size="lg"
      v-model="modalShow"
    >
      <b-form
        @submit.prevent
      >
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            id="input-client"
            v-model="stringSearchClient"
            :placeholder="$t('selectClient')"
            @keyup="validationForm"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="validationForm"
            >{{ $t('Buscar') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <div class="mt-2" >
        <b-table
          :fields="fields"
          :items="listClients"
        >
          <template #cell(Nombre)="data">
            <span
              class="cursor-pointer"
              @click="setCurrentClient(data.item)"
            >
              {{ data.item.name }}
            </span>
          </template>
        </b-table>
      </div>
       <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BButton, BFormInput, BInputGroup, BModal, BInputGroupAppend, VBModal, BTable,BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination
  },
  computed: {
    ...mapGetters({
      listClients: 'clients/getItems',
      totalItems: 'clients/getTotalItems',
      listClientsFrecuentes: 'clients/getListClientsFrecuentes',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      user: 'auth/getUser',
      role: 'auth/getRole',
    }),
    modalShow: {
      get() { return this.$store.getters['clients/getShowModalClients'] },
      set(val) { this.$store.commit('clients/setShowModalClients', val) },
    },
    isHideHeader() {
      if (this.role === 'admin_cliente') {
        return true
      }
      return false
    },
  },
  created() {
    if (this.currentCompany && (this.currentClient === '' || this.currentClient === null) &&  this.role === 'admin_cliente') {
      this.modalShow = true
    }
  },
  data() {
    return {
      stringSearchClient: '',
      fields: [
        {
          key: 'Nombre',
          label: this.$t('Todos'),
        },
      ],
      fieldsFrecuentes: [
        {
          key: 'Nombre',
          label: this.$t('UsoFrecuente'),
        },
      ],
        currentPage: 1,
         pages: ['10', '15', '25'],
      pageLength: 10,
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
   watch: {
    currentPage() {
      this.validationForm()
    },
    pageLength() {
      this.validationForm()
    },

  },
  methods: {
    ...mapActions({
      getListClients: 'clients/getListClients',
    }),
    ...mapMutations({
      setCurrentClient: 'clients/setCurrentClient',
    }),
    validationForm() {
      this.getListClients({ company: this.currentCompany.id, page: this.currentPage, per_page: this.pageLength, search: this.stringSearchClient})
    },
  },
}
</script>
