const types = JSON.parse(localStorage.getItem('typesAvailables'))
const output = [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  }
];

if (types) {
  if (types.installations) {
    output.push({
      title: "installations",
      route: "installations",
      icon: "CpuIcon",
    })
  }
  if (types.assets) {
    output.push({
      title: "assets",
      route: "assets",
      icon: "DatabaseIcon",
    })
  }
  if (types.epis) {
    output.push({
      title: "epis",
      route: "epis",
      icon: "ServerIcon",
    })
  }
}

const empresaChildrens = [
  {
    title: "Datos empresa",
    route: "viewClient",
  },
  {
    title: "Localizaciones",
    route: "locations",
  },
  {
    title: "Usuarios",
    route: "users",
  },
  {
    title: "Formularios",
    route: "forms",
  },
];
if (types) {
  if (types.installations) {
    empresaChildrens.push({
      title: "Clientes",
      route: "subclients",
    })
  }
}

output.push(
  {
    title: "Tareas",
    route: "tasks",
    icon: "ClipboardIcon",
  },
  {
    title: "Mantenimiento",
    route: "maintenance",
    icon: "ListIcon",
  },
  {
    title: "Notificaciones",
    route: "notifications",
    icon: "BellIcon",
  },
  {
    title: "Etiquetas",
    icon: "TagIcon",
    children: [
      {
        title: "Etiquetas",
        route: "tags",
      },
      {
        title: "Series",
        route: "series",
      },
    ],
  },
  {
    title: "GestionProductos",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Productos",
        route: "products",
      },
      {
        title: "Categorías",
        route: "categories",
      }
    ],
  },
  {
    title: "Gestión",
    icon: "SettingsIcon",
    children: empresaChildrens,
  },
);

export default output
